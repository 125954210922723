/**
 * Dom Helpers
 */
window.qs = document.querySelector.bind(document)
window.qsa = document.querySelectorAll.bind(document)
Element.prototype.on = Element.prototype.addEventListener

/**
 * Пустые линки : a href="#"
 */
!!qs('a[href^="#"]') && qs('a[href^="#"]').on('click', e => e.preventDefault())
