// (function() {...})()

// Enable LiveReload def port 35729
// !!window.LR && document.write(`
//   <script src="http://${(location.host || 'localhost').split(':')[0]}:35729/livereload.js?snipver=1"></script>
// `);

// TODO : разобрать на модули и бандлы
// подключать там, где это необходимо...

/**
 * Helpers
 */
import '@/helpers/h.dom'
import '@/helpers/h.email-hide'
import '@/helpers/h.target-blank'
import exists from '@/helpers/h.exists'
import clickArea from '@/helpers/h.click-area'
import popup from '@/helpers/h.popup'
import userAgent from '@/helpers/h.user-agent'

/**
 * Добавляем класс на основе юзерагента
 */
;(function() {
  const body = document.documentElement
  if (userAgent.Android) body.setAttribute('data-ua', 'Android')
  if (userAgent.iOS) body.setAttribute('data-ua', 'iOS')
  if (userAgent.Opera) body.setAttribute('data-ua', 'Opera')
  if (userAgent.Windows) body.setAttribute('data-ua', 'Windows')
})()

/**
 * Фиксим баг в мобильном сафари при смене ориентации
 */
if (userAgent.iOS) {
  let viewportmeta = document.querySelector('meta[name="viewport"]')
  if (viewportmeta) {
    viewportmeta.content =
      'width=device-width, minimum-scale=1.0, maximum-scale=1.0'
    document.addEventListener(
      'orientationchange',
      function() {
        viewportmeta.content =
          'width=device-width, minimum-scale=0.25, maximum-scale=1.0'
      },
      false
    )
  }
}

/**
 * Open popup window from share link
 */
// !!qs('.popup') && qs('.popup').on('click', e => {
if (exists('.js-popup')) {
  ;[...qsa('.js-popup')].forEach(item =>
    item.on('click', e => {
      e.preventDefault()
      popup(e)
    })
  )
}

/**
 * Lazy loading images
 * https://github.com/toddmotto/echo
 */
// import echo from '@/vendors/echo-1.7.3';
// echo.init({
//   offset: 100,
//   throttle: 250,
//   callback: (item, op) => {
//     const itemName = item['src'].split('/').pop()
//     // console.log(itemName, 'has been', op + 'ed')
//     console.log(
//       `%c ${itemName} `,
//       'background:brown; color:white; display:inline-block; padding:2px',
//       `has been ${op}ed`
//     )
//   }
// });

/**
 * Lazy loading images
 * https://github.com/ApoorvSaxena/lozad.js
 * We use https://polyfill.io/v2/polyfill.min.js?features=IntersectionObserver
 * before load app.js
 */
// import lozad from '@/vendors/lozad'
import lozad from 'lozad'
const observer = lozad('[data-src]', {
  rootMargin: '10px 0px', // syntax similar to that of CSS Margin
  threshold: 0.1, // ratio of element convergence
  load(el) {
    // console.log('loading element', el)
    // Custom implementation to load an element
    // el.src = el.getAttribute('data-src')
    el.src = el.dataset.src
    el.parentElement.classList.add('loaded')
  }
}).observe()

/**
 * Frontpage News Slider
 */
if (exists('.js-home-news .nums')) {
  const newsList = [...qsa('.js-home-news article')]
  const buttonList = [...qsa('.js-home-news .nums a')]

  buttonList.forEach((button, index) =>
    button.on('click', e => {
      // Активные кнопки
      buttonList.forEach(el => el.classList.remove('is-active'))
      button.classList.add('is-active')
      // Активные записи
      newsList.forEach(el => el.classList.remove('is-active'))
      newsList[index].classList.add('is-active')
    })
  )

  // Кликаем самый первый
  buttonList[0].click()

  // Вешаем клик на весь артикль
  newsList.forEach(el => clickArea(el, 'h2 a'))
}

/**
 * Frontpage Big Banner Slider
 * https://github.com/pawelgrzybek/siema
 */
import Siema from '@/vendors/siema'
if (exists('.js-siema')) {
  // New siema instance
  const mySiema = new Siema({
    selector: '.js-siema',
    duration: 250,
    loop: true,
    onInit: createControls,
    onChange: highlightCurrent
  })

  function createControls() {
    this.selector.insertAdjacentHTML(
      'afterend',
      '<div class="siema-navigation js-siema-navigation">'
    )
    this.navigation = qs('.js-siema-navigation')

    this.selector.insertAdjacentHTML(
      'afterend',
      '<div class="siema-pagination js-siema-pagination">'
    )
    this.pagination = qs('.js-siema-pagination')
  }

  function highlightCurrent() {
    const btns = [...qsa('.js-siema-pagination button')]
    btns.forEach(btn => btn.classList.remove('is-active'))
    btns[this.currentSlide].classList.add('is-active')
  }

  // Generates navigations
  Siema.prototype.addNavination = function() {
    this.navigation.insertAdjacentHTML(
      'beforeend',
      `
      <button class="siema-prev js-siema-prev">Prev</button>
      <button class="siema-next js-siema-next">Next</button>
    `
    )
    qs('.js-siema-prev').on('click', e => this.prev())
    qs('.js-siema-next').on('click', e => this.next())
  }
  mySiema.addNavination()

  // Generates pagination
  Siema.prototype.addPagination = function() {
    for (let i = 0; i < this.innerElements.length; i++) {
      const btn = document.createElement('button')
      btn.textContent = i + 1
      btn.addEventListener('click', e => this.goTo(i))
      this.pagination.appendChild(btn)
    }
    // Highlight first on init
    this.pagination.childNodes[0].classList.add('is-active')
  }
  mySiema.addPagination()

  // listen for keydown event
  setInterval(() => mySiema.next(), 5000)

  // listen for keydown event
  document.addEventListener('keydown', e => {
    e.keyCode === 37 && mySiema.prev()
    e.keyCode === 39 && mySiema.next()
  })
}

/**
 * Front Ticker for programs
 */
if (exists('.js-home-news .nums')) {
  const $tickerList = [...qsa('#ticker li')]
  const total = $tickerList.length
  let count = 0

  // добавим класс по новой
  const startOver = () =>
    $tickerList.forEach(
      $item => !$item.classList.contains('-out') && $item.classList.add('-out')
    )
  startOver()

  // анимируем исчезание
  const animateTicker = () => {
    if (count == total) (count = 0), startOver()
    $tickerList[count].classList.remove('-out')
    count++
  }
  setInterval(animateTicker, 2000)
}

/**
 * Navifgation Menues
 */
const $html = qs('html')

function toggleModal(e, $target) {
  e.preventDefault()
  const $trigger = e.target
  $trigger.classList.toggle('is-active')
  $target.classList.toggle('is-active')

  // Disables root fo animation overlays
  $html.classList.toggle('is-disabled')
  document.addEventListener('keydown', e => {
    if ($html.classList.contains('is-disabled') && e.keyCode == 27)
      toggleModal(e, $target)
  })
}

if (exists('.js-site-nav')) {
  qs('.js-site-nav-trigger').on('click', e =>
    toggleModal(e, qs('.js-site-nav'))
  )
}

if (exists('.js-sidebar-nav')) {
  qs('.js-sidebar-nav-trigger').on('click', e =>
    toggleModal(e, qs('.js-sidebar-nav'))
  )
  qs('.js-sidebar-nav-close').on('click', e => {
    toggleModal(e, qs('.js-sidebar-nav'))
    qs('.js-sidebar-nav-trigger').classList.toggle('is-active')
  })
}

/**
 * Классы для групп картинок в зависимости от размера
 */
// $('.entry figure:has(img)').each(function() {
//   if ($(this).find('img').length > 2) {
//     $(this).addClass('gallery cf');
//   }
//   if ($(this).find('img').hasClass('size-thumbnail')) {
//     $(this).addClass('thumbnail');
//   }
//   if ($(this).find('img').hasClass('size-medium')) {
//     $(this).addClass('medium');
//   }
//   if ($(this).find('img').hasClass('size-large')) {
//     $(this).addClass('large cf');
//   }
// });

/**
 * Magnific Popup [deprecated]
 * http://dimsemenov.com/plugins/magnific-popup/
 * http://dimsemenov.com/plugins/magnific-popup/documentation.html
 */
import './front/front.magnific'

/**
 * Заказ обратного звонка
 */
// import './forms/forms.callback'

/**
 * Open forms as Modal
 * order_call: обратный звонок
 * subscribe: подписка на рассылку
 */
// import OpenFrame from '@/scripts/modal/OpenFrame'
// new OpenFrame({
//   opener: '.js-open-form',
//   closeAfter: 1000
// })

/**
 * Subtoggle
 */
$('#subtoggle :header + div').hide()
$('#subtoggle h2').on('click', function(event) {
  $(this).toggleClass("active").next().slideToggle("slow");
})

/**
 * Toggle
 */
$('#toggled h4').on('click', function(event) {
  event.preventDefault()
  $(this)
    .parent('.tog')
    .toggleClass('active')
    .next('.hidden')
    .toggleClass('active')

  if (
    $(this)
      .parent('.tog')
      .hasClass('active')
  ) {
    $(this).data('dt', $(this).html())
    $(this).html('Скрыть подробности &uarr;')
  } else {
    $(this).html($(this).data('dt'))
  }
})
$('#toggled .h4').on('click', function(event) {
  event.preventDefault()
  $(this)
    .parent('.tog')
    .toggleClass('active')
    .next('.hidden')
    .toggleClass('active')

  if (
    $(this)
      .parent('.tog')
      .hasClass('active')
  ) {
    $(this).data('dt', $(this).html())
    $(this).html('Скрыть подробности &uarr;')
  } else {
    $(this).html($(this).data('dt'))
  }
})
$('#toggled .tog h2').attr('target', '_blank')

/**
 * Летние курсы
 */
$('.summer h2').each(function() {
  //$(this).nextUntil('h2').wrapAll('<article>');
  var href = $(this)
    .children('a')
    .attr('href')
  $(this)
    .next()
    .on('click', function() {
      document.location.href = href
    })
    .css('cursor', 'pointer')
})

/**
 * Google Analytics Goals
 */
if (typeof ga != 'undefined') {
  // Переходы на социальные сети школы
  ;[...qsa('[data-social]')].forEach(item =>
    item.on('click', e => {
      ga('send', 'event', 'social', 'click', item.dataset.social)
      ga(
        'send',
        'social',
        item.dataset.social,
        'transition',
        document.location.href
      )
    })
  )
  // Кнопки «Поделиться»
  ;[...qsa('[data-share]')].forEach(item =>
    item.on('click', e =>
      ga('send', 'social', item.dataset.share, 'share', document.location.href)
    )
  )
}
jQuery(function($){
  if (window.matchMedia("(max-width: 767px)").matches) {
    $(document).mouseup(function (e){ // событие клика по веб-документу
      var div = $(".l-sidebar.js-sidebar-nav"); // тут указываем ID элемента
      if (!div.is(e.target) // если клик был не по нашему блоку
          && div.has(e.target).length === 0) { // и не по его дочерним элементам
            $('.sidebar-nav-trigger.js-sidebar-nav-trigger, .l-sidebar.js-sidebar-nav, .nav-trigger-close.js-sidebar-nav-close').removeClass('is-active');
            $('html').removeClass('is-disabled');
      }
    });
  }
});
