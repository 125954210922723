// Enable webpack HMR for extracted resources
module.hot && module.hot.accept()

/**
 * DEVELOPMENT
 */
if (DEVELOPMENT && module.hot) {
  const link = document.querySelector("link[href*='assets/'][rel=stylesheet]");
  !!link && document.querySelector('head').removeChild(link);
  const preload = document.querySelector("link[href*='assets/'][as=style]");
  !!preload && document.querySelector('head').removeChild(preload);
}

/**
 * Polyfills
 */
// import 'es6-promise/auto'; // promise only
// import "babel-polyfill"; // > Huge!

// svgxuse : https://github.com/Keyamoon/svgxuse
// A simple polyfill that fetches external SVGs referenced 
// in <use> elements when the browser itself fails to do so.
// import './vendors/h.svgxuse.min'
// <script src="svgxuse.js" defer></script>

/**
 * App config
 */
// import CONFIG from './config'

// TODO : донастроить сборку спрайтов в рантайме
// https://github.com/kisenka/svg-sprite-loader
// import close from './sprites/app/mono-close.svg'
// console.log(close)

/**
 * SVG https://github.com/svgstore/webpack-svgstore-plugin
 */
const __svg__ = {
  path: 'sprites/app/*.svg',
  name: 'app.sprite.svg' // 'app.sprite.[hash].svg'
}
// svgstore(__svg__)
import svgstore from '@/vendors/h.svgxhr-simple'
if (DEVELOPMENT && module.hot) {
  svgstore(`${HOT_SERVER_ASSETS}app.sprite.svg`);
}

/**
 * Vendors
 */
import { touchAction } from './vendors/h.touch-action'
touchAction()

import { sweetAction } from './vendors/sweet-alert.min'
sweetAction()

// import { inputmaskAction } from './vendors/inputmask.bundle.3.2.7.js'
// inputmaskAction()

/**
 * Helpers
 */
import '@/helpers/h.dom'

/**
 * Линки на меня
 */
const ollejah = qs('a[href^="//ollejah.ru/"]');
!!ollejah && ollejah.on('click', e =>
  e.target.attr('href', '//ollejah.ru/?utm_content=Footer+Link&utm_medium=referrer&utm_source=' + document.location.host)
)

/**
 * Front app bundle
 */
// import $ from 'jquery';
import '@/scripts/front';

import bvi from 'bvi'
import { getCookie } from './utils/cookie.js'



(function ($) {

  function e() {
    var K = getCookie("images");
    console.log('K', K);
    $(".bvi-body *").each(function () {
      var N = $(this);
      var L = N.css("background-image");
      var M = L.replace(/^url\(['"]?/, "").replace(/['"]?\)$/, "");
      if (M != "none") {
        $(this).addClass("bvi-background-image")
      }
    });
    $("img").each(function () {
      if(!$(this).hasClass("bvi-no-styles")){
        $(this).addClass("bvi-img");

      }
    });
    if (K === "true") {
      $("img").each(function () {
        $(this).show();
        $("div.bvi-img").remove();
        if (J == 11 || J == 10 || J == 9) {
          var L = $(this).attr("data-bvi-img-original") || this.src;
          this.src = L
        }
      });
      $(".bvi-body *").each(function () {
        var P = $(this);
        var L = P.css("background-image");
        var N = L.replace(/^url\(['"]?/, "").replace(/['"]?\)$/, "");
        if (N != "none") {
          if (J == 11 || J == 10 || J == 9) {
            var M = "^(https?|http)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]";
            if (N.match(M)) {
              var O = P.attr("data-bvi-background-image-original") || N;
              P.css("background-image", "url(" + O + ")")
            }
          }
        }
      })
    }
    if (K === "false") {
      $("div.bvi-img").remove();
      $("img").each(function () {
        if(!$(this).hasClass("bvi-no-styles")){
        $(this).hide();
       
        var N = this.alt || "Изображение";
        var M = $(this).attr("class") || "bvi-class-none";
        var L = $(this).attr("id") || "bvi-id-none";
        $(this).after($('<div class="' + M + '" id="' + L + '" style="height: 100%;">').html(N))
      }
      })
    }
    if (K === "grayscale") {
      $("img").each(function () {
        $(this).show();
        $("div.bvi-img").remove();
        if (J == 11 || J == 10 || J == 9) {
          $(this).attr("data-bvi-img-original", this.src);
          if (location.hostname === j(this.src)) {
            var L = u(this.src);
            this.src = L
          } else {
            return false
          }
        }
      });
      $(".bvi-body *").each(function () {
        var P = $(this);
        var M = P.css("background-image");
        var O = M.replace(/^url\(['"]?/, "").replace(/['"]?\)$/, "");
        if (O != "none") {
          if (J == 11 || J == 10 || J == 9) {
            var N = "^(https?|http)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]";
            if (O.match(N)) {
              var L = u(O);
              P.attr("data-bvi-background-image-original", O);
              P.css("background-image", "url(" + L + ") !important")
            }
          }
        }
      })
    }
  }

  function z() {
    var M = window.navigator.userAgent;
    var L = M.indexOf("MSIE ");
    if (L > 0) {
      return parseInt(M.substring(L + 5, M.indexOf(".", L)), 10)
    }
    var K = M.indexOf("Trident/");
    if (K > 0) {
      var O = M.indexOf("rv:");
      return parseInt(M.substring(O + 3, M.indexOf(".", O)), 10)
    }
    var N = M.indexOf("Edge/");
    if (N > 0) {
      return parseInt(M.substring(N + 5, M.indexOf(".", N)), 10)
    }
    return false
  }

  var J = z();

  new bvi.Bvi({
    images: false,
    builtElements: true
  });

  $(document).on('click', '[data-bvi-btn="close"]', function () {
    console.log('close');
    $('html').removeAttr('style');
    $('body').css('margin', '0 auto');
    $(".bvi-body *").each(function () {
      var N = $(this);
      var K = N.css("background-image");
      var L = K.replace(/.*\s?url\([\'\"]?/, "").replace(/[\'\"]?\).*/, "");
      if (L != "none") {
        if (J == 11 || J == 10 || J == 9) {
          var M = N.attr("data-bvi-background-image-original") || L;
          N.css("background-image", "url(" + M + ")");
          N.removeClass("bvi-background-image")
        }
      }
    });
    $("img").each(function () {
      $(this).show();
      $("div.bvi-img").remove();
      $(this).removeClass("bvi-background-image");
      if (J == 11 || J == 10 || J == 9) {
        var K = $(this).attr("data-bvi-img-original") || this.src;
        this.src = K
      }
    });
  })

  $('.bvi-open') && $('.bvi-open').click(function () {
    e()
    console.log('open');
    $('html').css('background', 'none');
    $('body').css('margin', '0 auto');
  })


  if ($('body').hasClass('bvi-active')) {
    e()
    $('html').css('background', 'none');
    $('body').css('margin', '0 auto');

  } else {
    $('html').removeAttr('style');
    $('body').css('margin', '0 auto');
  }

  $(document).on('click', '[data-bvi-btn-images]', function() {
    e();
  })

 



})(jQuery);

