export function sweetAction(opts) {
    var s
    if (!opts) opts = {}
    if (!opts.src) opts.src = 'https://cdn.jsdelivr.net/npm/sweetalert2@8'
    if (!opts.val) opts.val = 'manipulation'

    if ('sweetAction' in document.body.style) {
        document.body.style.sweetAction = opts.val
    } else {
        s = document.createElement('script')
        s.src = opts.src
        s.async = true
        s.onload = function onload() {
            console.log('sweet alier init');
        }
        document.body.appendChild(s)
    }
}