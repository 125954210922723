import '@/helpers/h.dom';
import exists from '@/helpers/h.exists';

/**
 * Найдем все ссылки на внешний URI
 */
const ids = window.location.hostname;
const static_ids = 'static.designschool.ru';

[...qsa(`
  a[href^="http"]:not([href*="${ids}"]):not([href*="${static_ids}"]),
  a[href^="//"]:not([href*="${ids}"]):not([href*="${static_ids}"])
`)].forEach(item => item.setAttribute('target', '_blank'));

/**
 * Опасный target="_blank" 
 */
if (exists('a[target="_blank"]')) {
  [...qsa('a[target="_blank"]')].forEach(item => {
    window.opener = null
    item.setAttribute('rel', 'nofollow noopener')
  })
}
