import '@/helpers/h.dom';
import exists from '@/helpers/h.exists';

/**
 * Simple spam protection for email addresses
 * <a href="mailto:foo(at)example(dot)com">foo at example dot com</a>
 */
if (exists('a[href^="mailto:"]')) {
  const email = qs('a[href^="mailto:"]');
  email.href = email.href.replace('(at)', '@').replace(/\(dot\)/g, '.');
}
