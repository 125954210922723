/**
 * POPUP 
 * новые окна, например, для соцсетей
 * Twitter, Facebook
 */

import './h.dom'

// Here is a cross browser solution with pure JavaScript
// http://www.w3schools.com/jsref/prop_win_innerheight.asp
const VW = window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const VH = window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export default function(event, width = 640, height = 400) {
  let left = (VW - width) / 2,
    top = (VH - height) / 2,
    url = event.target.href,
    opts = `status=0,menubar=0,location=0,toolbar=0
    ,width=${width},height=${height}
    ,top=${top},left=${left}`;

  window.open(url, 'popup', opts + '')
}
