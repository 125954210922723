/**
 * Мобильные хелперы
 * получаем UA устройства
 * device.iOS
 * device.android
 * device.all
 */

export default {
  Android: navigator.userAgent.match(/Android/i),
  BlackBerry: navigator.userAgent.match(/BlackBerry/i),
  iOS: navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: navigator.userAgent.match(/Opera Mini/i),
  Windows: navigator.userAgent.match(/IEMobile/i),
  all: (/iPhone|iPod|iPad|Android|webOS|BlackBerry|Windows Phone|ZuneWP7/gi).test(navigator.appVersion),
}