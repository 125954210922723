/**
 * clickArea 
 * Вешаем клик на весь блок, содержащий ссылку
 * @param  {DOM} pointer элемент, инициирущий переход
 * @param  {String} children дочерний элемент, где искать ссылку
 * 
 * @use items.forEach(el => clickArea(item, 'h2 a'))
 * @use clickArea(item, 'h2 a')
 */

import './h.dom'

export default function (pointer, children) {
  const item = pointer.querySelector(children)
  item && pointer.on('click', () => {
    if (item.href) window.location.href = item.href
    return false
  })
}