/**
 * magnificPopup
 * http://dimsemenov.com/plugins/magnific-popup/
 * http://dimsemenov.com/plugins/magnific-popup/documentation.html
 */

import '@/vendors/magnific-popup/jquery.magnific-popup';
import userAgent from '@/helpers/h.user-agent';

var galleryEnabled = galleryEnabled || false;
var $images = $('main a[href*="/uploads/"]:has(img), main a[href*="/albums/"]:has(img)');

$images.each(function() {
  // Парсим описание к картинке из атрибутов
  var imageTitle = $(this).children('img').attr('title');
  var imageAlt = $(this).children('img').attr('alt');
  if ($(this).attr('title') == undefined || $(this).attr('title') == null) {
    if (imageTitle) $(this).attr('title', imageTitle);
    if (imageAlt) $(this).attr('title', imageAlt);
  }

  // Добавляем классы в соответствии с размером картинки
  // $(this).addClass('thumb');

  // включаем галерею если картинок больше одной
  if ( $('main').find('img').length > 1 ) galleryEnabled = true;
});

/**
 * Картинки
 */
$images.magnificPopup({
  gallery: {
    enabled: galleryEnabled, // включаем галерею если картинок больше одной
    tCounter: '%curr% из %total%' // markup of counter
  },
  // delegate: 'a',
  type: 'image',
  image: {
    markup: `
      <div class="mfp-figure">
        <div class="mfp-img"></div>
        <div class="mfp-bottom-bar">
          <div class="mfp-title"></div>
          <div class="mfp-counter"></div>
        </div>
      </div>`,
    verticalFit: true,
    // titleSrc: function(item) {
    //   return item.el.parents('.entry').find('h1').text();
    // }
  },
  closeBtnInside: false,
  // mainClass: 'mfp-with-fade',
  mainClass: 'mfp-zoom-in',
  closeOnContentClick: true,
  preload: [0, 2],
  // http://codepen.io/dimsemenov/pen/GAIkt?
  tLoading: '',
  removalDelay: 500,
  callbacks: {
    lazyLoad: function(item) {
      console.log(item); // Magnific Popup data object that should be loaded
    },
    imageLoadComplete: function() {
      var self = this;
      setTimeout(function() {
        self.wrap.addClass('mfp-image-loaded');
      }, 16);
    },
    close: function() {
      this.wrap.removeClass('mfp-image-loaded');
    }
  },
});

/**
 * Формы
 */
if (!userAgent.all) {
  // $('a[href*="/forms/"], a[href*="/ext/"]:not(".js-open-form"), a[href*="/requests/"], a[href*="/zforms/"]:not(".js-open-form")').magnificPopup({
  $('a[href*="/ext/"], a[href*="/requests/"], a[href*="//forms.designschool.ru/"]').magnificPopup({
    type: 'iframe',
  });

    (function ($) {
        $(function () {
            let study_btn = $('.study-info .btn-group .reCallback').data("href");
            let study_btn_vebinar = $('.study-info .btn-group .popup-modal.btn').data("mfp-src");
            let footer_study_btn = $('.sticky-footer .btn-subscribe.btn-green');
            if (study_btn || study_btn_vebinar) {
                $('.sticky-footer').show();
                $('.l-footer').addClass('sticky-footer-show');
                $('.footer__btn-for-blind__wrapper').addClass('sticky-footer-for-blind');

                if (study_btn) {
                    $(footer_study_btn).attr('data-href', study_btn);
                } else {
                    $(footer_study_btn).attr('data-mfp-src', study_btn_vebinar).attr('href', study_btn_vebinar).removeClass('reCallback').addClass('vebinar_footer_btn');
                }

            } else {
                $('.sticky-footer').remove();
                $('.l-footer').removeClass('sticky-footer-show');

            }

            let btns = $('.reCallback');
            btns.each(function (index, elem) {
                let href = '//forms.designschool.ru/' + $(elem).attr('data-href');
                $(elem).magnificPopup({
                    type: 'iframe',
                    items: {
                        src: href
                    }
                });
            });
        });
    }(jQuery));

  $('a[href*="/pay/"]').magnificPopup({
    // iframe: {
    //   markup: '<div class="mfp-iframe-scaler">' +
    //     '<div class="mfp-close"></div>' +
    //     '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
    //     '</div>',
    // },
    type: 'iframe',
  });

} else {
    console.log("mobile");
    let study_btn = $('.study-info .btn-group .reCallback').data("href");
    let footer_study_btn = $('.sticky-footer .btn-subscribe.btn-green');
    $(footer_study_btn).attr('data-href', study_btn);
    if (study_btn) {
        $('.sticky-footer').show();
        $('.footer__btn-for-blind__wrapper').addClass('sticky-footer-for-blind');
    } else {
        $('.sticky-footer').show();
        $('.footer__btn-for-blind__wrapper').addClass('sticky-footer-for-blind');
        $(footer_study_btn).remove();
    }
    $('.reCallback').each(function (index, elem) {
        $(elem).on('click', function (event) {
            let href = '//forms.designschool.ru/' + $(elem).attr('data-href');
            console.log(href);
            window.open(href);
            //document.location.href = href;
        });
    });
}
$('.popup-modal').magnificPopup({
    type:'inline',
    preloader: false,
    modal: false,
    callbacks: {
        open: function() {
            console.log("open");
        },
        close: function() {
            console.log("close");
        }
    }
});

$('.popup-youtube').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    callbacks: {
        open: function() {
            console.log("open");
        },
        close: function() {
            console.log("close");
        }
    }
});

$('#video').magnificPopup({
    items: {
        src: $('#video').attr('href') /*'https://www.youtube.com/watch?v=OJjdkDM6i-k'*/
    },
    type: 'iframe',
    mainClass: 'video_frame_wrapper',
    iframe: {
        markup: '<div class="mfp-iframe-scaler">'+
            '<div class="mfp-close"></div>'+
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
            '</div>',
        patterns: {
            youtube: {
                index: 'youtube.com/',
                id: 'v=',
                src: '//www.youtube.com/embed/%id%?autoplay=1'
            }
        },
        srcAction: 'iframe_src',
    }
});

$(document).ready(function(){
    $('.vebinar_footer_btn').magnificPopup({
        type:'inline',
        preloader: false,
        modal: false,
        callbacks: {
            open: function() {
                console.log("open");
            },
            close: function() {
                console.log("close");
            }
        }
    });
   // $(document).on('click', '.vebinar_footer_btn', function(e){
   //
   // });
});


/**
 * Видео
 */
// if (!userAgent.all) {
//     $('a[href*="youtube"]:not([href*="designschoolmsk"]), a[href*="vimeo"]').magnificPopup({
//         type: 'iframe',
//         // markup: '<div class="mfp-iframe-scaler">' +
//         //     '<div class="mfp-close"></div>' +
//         //     '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
//         //     '</div>',
//     });
// }
